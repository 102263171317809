import {useForecastContext} from "../../contexts/ForecastContextProvider";
import dayjs from "dayjs";
import {forwardRef, useCallback, useEffect, useMemo, useState} from "react";
import {getPointForecast} from "../../services/api";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import {processPointData} from "./utils";
import {TbSunrise, TbSunset} from "react-icons/tb";
import {useTranslation} from "react-i18next";
import {MdSunny, MdWbCloudy} from "react-icons/md";
import {IoRainy} from "react-icons/io5";
import {FaLocationArrow} from "react-icons/fa";

const PointTable = forwardRef(({}, ref) => {
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const {position, date, model} = useForecastContext();
    const [data, setData] = useState(null);
    const systemPrefersDark = localStorage.getItem("color-theme") === 'dark';


    useEffect(() => {
        if (position && date && model) {
            (async () => {
                const times = Array.from({length: 3}, (_, i) => {
                    return Array.from({length: 11}, (_, j) => {
                        // start from local hour 8
                        const hour = j + 8;
                        return dayjs(date).add(i, 'day').hour(hour).minute(model === 'ICON' ? 0 : 30).utc().format('YYYYMMDDHHmm');
                    })
                }).flat();
                setData(null)
                const data = await getPointForecast(position.lat, position.lng, times, model);
                setData(processPointData(data, date, model, times, currentLanguage));
            })()
        }
    }, [date, model, position]);

    const calculateSoarableMslChartMax = useMemo(() => {
        let max = -9999;
        if (!data) return max;
        for (let i = 0; i < data?.d.length; i++) {
            data?.d[i][1] > max && (max = 1.25 * data?.d[i][1])
            data?.cumulusCloudBase[i][2] > max && data?.cumulusCloudBase[i][2] > data?.cumulusCloudBase[i][1] && (max = 1.08 * data?.cumulusCloudBase[i][2]);
        }
        return max;
    }, [data])

    const soarableHeightChartOptions = useMemo(() => ({
        title: {
            text: ''
        },
        plotOptions: {
            series: {
                pointPlacement: 'on',
                animation: {
                    duration: 1500,
                    colorByPoint: true
                },
                threshold: null,
                marker: {enabled: false},
                enableMouseTracking: false,
            },

        },
        chart: {
            type: 'area',
            height: 180,
            marginBottom: 5,
        },

        legend: {enabled: false},
        xAxis: {
            labels: {enabled: false},
            tickLength: 0,
        },
        yAxis: {
            min: data?.rawData['hwcrit'][0] - data?.rawData['dwcrit'][0],
            max: calculateSoarableMslChartMax,
            gridLineWidth: 1,
            gridLineColor: 'rgba(128, 128, 128, 0.1)',
            title: '',
            labels: {
                format: `{value} m`,
                style: {
                    fontSize: '13px',
                    color: systemPrefersDark ? '#FFF' : '#000'

                },
                x: -20,
            },
            startOnTick: false,
            endOnTick: false,
            tickInterval: 300,

        },
        series: [{
            name: 'Height of Critical Updraft Strength',
            type: 'line',
            color: 'red',
            data: data?.d,
            lineWidth: 3,
        }, {
            name: 'Cumulus cloudbase',
            type: 'arearange',
            color: '#F071B4',
            fillColor: 'gray',
            data: data?.cumulusCloudBase,
            lineWidth: 3,
        }],
        tooltip: {
            enabled: false
        }
    }), [calculateSoarableMslChartMax, data, systemPrefersDark])

    const surfaceTemperatureChartOptions = useMemo(() => ({
        title: {
            text: ''
        },
        plotOptions: {
            series: {
                pointPlacement: 'on',
                animation: {
                    duration: 1500,
                    colorByPoint: true
                },
                threshold: null,
                marker: {enabled: false},
                enableMouseTracking: false,

            }
        },
        chart: {
            type: 'line',
            height: 150,
            marginBottom: 10,
        },

        legend: {
            enabled: false
        },
        xAxis: {
            labels: {enabled: false},
            tickLength: 0,
        },
        yAxis: {
            max: data?.surfaceTemperature ? Math.max(...data?.surfaceTemperature.map(item => item[1])) + 5 : 30,
            gridLineWidth: 1,
            gridLineColor: 'rgba(128, 128, 128, 0.1)',
            title: '',
            labels: {
                format: `{value} °C`,
                style: {
                    fontSize: '13px',
                    color: systemPrefersDark ? '#FFF' : '#000'
                },
                x: -20,
            },
            endOnTick: false,
            tickInterval: 5,

        },
        series: [{
            type: 'line',
            color: 'red',
            data: data?.surfaceTemperature,
            lineWidth: 3,
        }, {
            type: 'line',
            color: '#1D45AF',
            data: data?.surfaceDewpoint,
            lineWidth: 3,
        }],
        tooltip: {
            enabled: false
        }
    }), [data, systemPrefersDark])

    const pressureChartOptions = useMemo(() => ({
        title: {
            text: ''
        },
        plotOptions: {
            series: {
                pointPlacement: 'on',
                animation: {
                    duration: 1500,
                    colorByPoint: true
                },
                threshold: null,
                marker: {enabled: false},
                enableMouseTracking: false,

            }
        },
        chart: {
            type: 'line',
            height: 115,
            marginBottom: 10,
        },

        legend: {
            enabled: false
        },
        xAxis: {
            labels: {enabled: false},
            tickLength: 0,
        },
        yAxis: {
            // max:data?.mslPressures ? Math.max(...data?.mslPressures.map(item => item[1])) + 5 : 30,
            gridLineWidth: 1,
            gridLineColor: 'rgba(128, 128, 128, 0.1)',
            title: '',
            labels: {
                format: `{value} hPa`,
                style: {
                    fontSize: '13px',
                    color: systemPrefersDark ? '#FFF' : '#000'
                },
                x: -15,
            },
            tickInterval: 5,

        },
        series: [{
            type: 'line',
            color: 'pink',
            data: data?.mslPressure,
            lineWidth: 3,
        }],
        tooltip: {
            enabled: false
        }
    }), [data, systemPrefersDark])


    const renderCloudCover = useCallback((type) => {
        const cloudCover = [];
        for (let i = 0; i < data?.times?.length; i++) {
            cloudCover.push(<td key={`${type}${i}`}
                                style={{backgroundColor: data?.rawData?.[type][i] > 0 ? `rgba(150, 150, 150,${data?.rawData[type][i] / 100}` : systemPrefersDark ? '#004080' : 'lightblue'}}></td>)
        }
        return cloudCover;
    }, [data])

    const renderCuClouds = useCallback(() => {
        const cuClouds = [];
        for (let i = 0; i < data?.times?.length; i++) {
            cuClouds.push(<td key={`CFRACL${i}`}
                              style={{backgroundColor: data?.rawData?.['CFRACL'][i] > .2 ? `rgba(150, 150, 150,${data?.rawData['CFRACL'][i] / 100}` : systemPrefersDark ? '#004080' : 'lightblue'}}>
                {data?.rawData?.['accrain'][i] <= 0 ? data?.rawData?.['zsfclcldif'][i] > 0 ?
                        <MdWbCloudy
                            size={25}
                            className="w-full text-[#ECF0F1]"/> : data?.rawData?.['CFRACM'][i] < .1 && data?.rawData?.['CFRACH'][i] < .1 && data?.rawData?.['CFRACL'][i] < .15 &&
                        <MdSunny size={25} className="w-full text-[#FFD700]"/> :
                    data?.rawData?.['accrain'][i] > 0 && <IoRainy size={25} className="w-full text-[#919FA1]"/>}
            </td>)
        }
        return cuClouds;
    }, [data])

    const renderSurfaceHeating = useCallback(() => {
        const surfaceHeating = [];
        for (let i = 0; i < data?.times?.length; i++) {
            surfaceHeating.push(<td key={`${'sfcshf'}${i}`}
                                    style={{backgroundColor: `rgba(255, 255, 0,${data?.rawData['sfcshf'][i] / 400}`}}></td>)
        }
        return surfaceHeating;
    }, [data])

    const renderThermalStrength = useCallback(() => {
        const thermalStrength = [];
        for (let i = 0; i < data?.times?.length; i++) {
            thermalStrength.push(<td key={`${'wstar'}${i}`}
                                     style={{backgroundColor: `rgba(255, 0, 0,${data?.rawData['wstar'][i] / 12}`}}>
                {Math.round(10 * data?.rawData['wstar'][i]) / 10}
            </td>)
        }
        return thermalStrength;
    }, [data])

    const renderSoarableMsl = useCallback(() => {
        const soarableMsl = [];
        for (let i = 0; i < data?.times?.length; i++) {
            soarableMsl.push(<td key={`${'thwcrit'}${i}`}>{Math.round(data?.rawData['hwcrit'][i])}</td>)
        }
        return soarableMsl;
    }, [data])

    const renderSoarableAgl = useCallback(() => {
        const soarableAgl = [];
        for (let i = 0; i < data?.times?.length; i++) {
            soarableAgl.push(<td key={`${'tdwcrit'}${i}`}>{10 * Math.round(data?.rawData['dwcrit'][i] / 10)}</td>)
        }
        return soarableAgl;
    }, [data])

    const renderSurfaceTemperature = useCallback(() => {
        const surfaceTemperature = [];
        for (let i = 0; i < data?.times?.length; i++) {
            surfaceTemperature.push(<td key={`${'sfctemp'}${i}`}
                                        style={{backgroundColor: data?.rawData?.['sfctemp'][i] < 15 ? `rgba(0, 0, 255,${(15 - data?.rawData['sfctemp'][i]) / 100}` : `rgba(255, 0, 0,${(data?.rawData['sfctemp'][i] - 15) / 100}`}}>
                {Math.round(data?.rawData['sfctemp'][i])}°
            </td>)
        }
        return surfaceTemperature;
    }, [data])

    const renderSurfaceDewpoint = useCallback(() => {
        const surfaceDewpoint = [];
        for (let i = 0; i < data?.times?.length; i++) {
            surfaceDewpoint.push(<td key={`${'sfcdewpt'}${i}`}>
                {Math.round(data?.rawData['sfcdewpt'][i])}°
            </td>)
        }
        return surfaceDewpoint;
    }, [data])

    const renderWind = useCallback(() => {
        const wind = [];
        for (let i = 0; i < data?.times?.length; i++) {
            wind.push(<td key={`${'wind'}${i}`}
                          style={{backgroundColor: `rgba(0, 0, 255,${(data?.rawData['sfcwindspd'][i] - 2) / 35}`}}>
                {Math.round(data?.rawData['sfcwindspd'][i])}m/s
                <br/>
                <div className="flex flex-row items-center gap-2">
                    {Math.round(2 * data?.rawData['sfcwinddir'][i])}°
                    <span style={{transform: `rotate(${parseInt(2 * data?.rawData['sfcwinddir'][i]) + 135 || 135}deg)`}}>
                    <FaLocationArrow className='mr-1' size={12}/></span>
                </div>
                <small>{Math.round(data?.rawData['sfcgust'][i])}m/s</small>
            </td>)
        }
        return wind;
    }, [data])

    const renderThermalTopWind = useCallback(() => {
        const thermalTopWind = [];
        for (let i = 0; i < data?.times?.length; i++) {
            thermalTopWind.push(<td key={`${'bltopwindspd'}${i}`}
                                    style={{backgroundColor: `rgba(0, 0, 255,${(data?.rawData['bltopwindspd'][i] - 4) / 35}`}}>
                {Math.round(data?.rawData['bltopwindspd'][i])}m/s
                <br/>
                <div className="flex flex-row items-center gap-2">
                    {Math.round(2 * data?.rawData['bltopwinddir'][i])}°
                    <span style={{transform: `rotate(${parseInt(2 * data?.rawData['bltopwinddir'][i]) + 135 || 135}deg)`}}>
                    <FaLocationArrow className='mr-1' size={12}/>
                    </span>
                </div>
            </td>)
        }
        return thermalTopWind;
    }, [data])


    if (!data) {
        return (<div
            className="flex justify-center items-center h-full flex-1 bg-zinc-100 dark:bg-slate-800 w-full">
            <div className="w-1/2 flex justify-center">
                <img src="/assets/images/logo.png" className=" animate-pulse dark:invert dark:brightness-0"
                     alt="loading"/>
            </div>
        </div>)
    }

    return <>
        <table className="text-sm point-table bg-white dark:bg-slate-800 text-black dark:text-white w-full h-full  m-3"
               ref={ref}>
            <thead>
            <tr className="h-[31px]">
                <th className="border-b-2">{new Date().toLocaleDateString(undefined, {
                    day: '2-digit',
                    timeZoneName: 'short'
                }).substring(4)}</th>
                {data.dates.map((date, index) => <td className="border-b-2" key={index} colSpan={11}><span
                    className="float-left flex items-center gap-2"><TbSunrise size={20}
                                                                              className="text-[#FFD700]"/>{dayjs.unix(data?.rawData['twilights'][index * 2]).format('HH:mm')}</span>{date}<span
                    className="float-right flex items-center gap-2 ">{dayjs.unix(data?.rawData['twilights'][index * 2 + 1]).format('HH:mm')}<TbSunset
                    size={20} className="text-[#eeaf61]"/></span>
                </td>)}
            </tr>
            <tr className="h-[31px]">
                <th className="border-b-2">{t('Time')}</th>
                {data.times.map((time, index) => <td className="border-b-2" key={index}>{time}</td>)}
            </tr>
            </thead>
            <tbody>
            <tr className="h-[48px]">
                <th className=" max-w-[130px]">{t('High Clouds Cover')}</th>
                {renderCloudCover('CFRACH')}
            </tr>
            <tr className="h-[48px]">
                <th className="max-w-[130px]">{t('Mid Clouds Cover')}</th>
                {renderCloudCover('CFRACM')}
            </tr>
            <tr className="h-[48px]">
                <th className="max-w-[130px]">{t('Low & Cu Clouds')}</th>
                {renderCuClouds()}
            </tr>
            <tr className="h-[30px]">
                <th className="max-w-[130px]">{t('Sfc Heating')}</th>
                {renderSurfaceHeating()}
            </tr>
            <tr className="h-[50px]">
                <th className="max-w-[130px]">{t('Thermal Strength (m/s)')}</th>
                {renderThermalStrength()}
            </tr>
            <tr className="h-[110px]">
                <th rowSpan={2} className="max-w-[130px] text-[#ff0000]">
                    {t('Soarable Height MSL (m)')}
                    <br/>
                    <span className="text-pink-400">{t('Condensation Level')}</span>
                </th>
                <td colSpan={data.times.length}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={soarableHeightChartOptions}
                    />
                </td>
            </tr>
            <tr className="h-[50px]">
                <th className="hidden"></th>
                {renderSoarableMsl()}
            </tr>
            <tr className="h-[50px]">
                <th className="max-w-[130px]">{t('Soarable Height AGL (m)')}</th>
                {renderSoarableAgl()}
            </tr>
            <tr className="h-[110px]">
                <th rowSpan={2} className="max-w-[130px] text-[#ff0000]">
                    {t('Sfc Temp (°C)')}
                </th>
                <td colSpan={data.times.length}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={surfaceTemperatureChartOptions}
                    />
                </td>
            </tr>
            <tr className="h-[50px]">
                <th className="hidden"></th>
                {renderSurfaceTemperature()}
            </tr>
            <tr className="h-[50px]">
                <th className="max-w-[130px] text-blue-700">{t('Sfc Dewpoint (°C)')}</th>
                {renderSurfaceDewpoint()}
            </tr>
            <tr className="h-[70px]">
                <th className="max-w-[130px]">{t('Wind (2m AGL)')} <br/><small>{t('Gusts')}</small></th>
                {renderWind()}
            </tr>
            <tr className="h-[50px]">
                <th className="max-w-[130px]">{t('Thermal Top Wind')}</th>
                {renderThermalTopWind()}
            </tr>
            <tr className="h-[85px]">
                <th rowSpan={2} className="max-w-[130px]">
                    {t('Pressure (hPa)')}
                </th>
                <td colSpan={data.times.length}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={pressureChartOptions}
                    />
                </td>
            </tr>
            </tbody>

        </table>
    </>
})

export default PointTable;

