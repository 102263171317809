import DataBox from "../DataBox";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";

const PressureBox = ({now, trend, all}) => {
    const {t} = useTranslation();

    const [minPressure, setMinPressure] = useState(0);
    const [minPressureTime, setMinPressureTime] = useState(dayjs().format('HH:mm'));
    const [maxPressure, setMaxPressure] = useState(0);
    const [maxPressureTime, setMaxPressureTime] = useState(dayjs().format('HH:mm'));
    useEffect(() => {
        if (all.length) {
            const minPressureData = getMinPressure();
            const maxPressureData = getMaxPressure();
            if(minPressureData && maxPressureData) {
                setMinPressure(minPressureData.pressure);
                setMinPressureTime(minPressureData.time);
                setMaxPressure(maxPressureData.pressure);
                setMaxPressureTime(maxPressureData.time);
            }
        }
    }, [all]);
    const getMinPressure = () => {
        return all.filter(item => dayjs(item.SEND_TIME).isAfter(dayjs().startOf('day'))).map(item => ({
            time: dayjs(item.SEND_TIME).format('HH:mm'),
            pressure: parseFloat(item.PRESSURE).toFixed(2)
        }))?.sort((a, b) => a.pressure - b.pressure)[0]
    };
    const getMaxPressure = () => {
        return all.filter(item => dayjs(item.SEND_TIME).isAfter(dayjs().startOf('day'))).map(item => ({
            time: dayjs(item.SEND_TIME).format('HH:mm'),
            pressure: parseFloat(item.PRESSURE).toFixed(2)
        }))?.sort((a, b) => b.pressure - a.pressure)[0]
    };

    return (
        <DataBox title={t('Vazdušni pritisak')} unit={t('hPa')}
                 value={now?.PRESSURE || 0}
                 subValue={`${t('Trend (1h)')}: ${trend?.diff_Pressure_hour || 0} ${t('hPa')}`}
                 sizes="w-1/2 sm:w-1/4"
                 uppercase
                 bottomLeft={
                     <div className="flex flex-col">
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Min')} {minPressure || 0} {t('hPa')}</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{minPressureTime || 0}</span>
                     </div>
                 }
                 bottomRight={
                     <div className="flex flex-col">
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Max')} {maxPressure} {t('hPa')}</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{maxPressureTime}</span>
                     </div>
                 }
        />
    )
}

export default PressureBox;
