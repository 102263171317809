import DataBox from "../DataBox";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const TemperatureBox = ({now, trend, all}) => {
    const {t} = useTranslation();

    const [minTemp, setMinTemp] = useState(0);
    const [minTempTime, setMinTempTime] = useState(dayjs().format('HH:mm'));
    const [maxTemp, setMaxTemp] = useState(0);
    const [maxTempTime, setMaxTempTime] = useState(dayjs().format('HH:mm'));
    useEffect(() => {
        if (all.length) {
            const minTempData = getMinTemp();
            const maxTempData = getMaxTemp();
            if(minTempData && maxTempData) {
                setMinTemp(minTempData.temp);
                setMinTempTime(minTempData.time);
                setMaxTemp(maxTempData.temp);
                setMaxTempTime(maxTempData.time);
            }
        }
    }, [all]);
    const getMinTemp = () => {
        return all.filter(item => dayjs(item.SEND_TIME).isAfter(dayjs().startOf('day'))).map(item => ({
            time: dayjs(item.SEND_TIME).format('HH:mm'),
            temp: parseFloat(item.TEMP).toFixed(2)
        }))?.sort((a, b) => a.temp - b.temp)[0]
    };
    const getMaxTemp = () => {
        return all.filter(item => dayjs(item.SEND_TIME).isAfter(dayjs().startOf('day'))).map(item => ({
            time: dayjs(item.SEND_TIME).format('HH:mm'),
            temp: parseFloat(item.TEMP).toFixed(2)
        }))?.sort((a, b) => b.temp - a.temp)[0]
    };
    return (
        <DataBox title={t('Temperatura')} unit="°C"
                 value={now?.TEMPERATURE || 0}
                 subValue={`${t('Trend (1h)')}: ${trend?.diff_Temp_hour || 0} °C`}
                 sizes="w-1/2 sm:w-1/4"
                 uppercase
                 bottomLeft={
                     <div className="flex flex-col">
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Min')} {minTemp}°C</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{minTempTime}</span>
                     </div>
                 }
                 bottomRight={
                     <div className="flex flex-col">
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Max')} {maxTemp}°C</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{maxTempTime}</span>
                     </div>
                 }
        />
    )
}

export default TemperatureBox;
