import {FaLocationArrow} from "react-icons/fa";
import {getWindDirection} from "../../utils";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getNow} from "../../services/api";
import {useTranslation} from "react-i18next";

const TakeOffSiteBox = ({takeOffSite}) => {
    const isSnap = navigator.userAgent === 'ReactSnap';
    const {t} = useTranslation();

    const navigate = useNavigate();
    const [stationData, setStationData] = useState(null);


    useEffect(() => {
        if (takeOffSite) {
            (async () => {
                const now = await getNow(takeOffSite.id);
                setStationData(now);
            })()
        }
    }, [takeOffSite]);


    const renderWindData = () => {
        if (!stationData) {
            return '------'
        }
        return (<>
            <div className="flex flex-col text-sm items-center mr-2">
                <FaLocationArrow style={{transform: `rotate(${parseInt(stationData?.WIND_ANG) + 135 || 135}deg)`}} className='mb-1' size={20}/>
                <span>{getWindDirection(stationData.WIND_ANG)}</span>
            </div>
            <div className="flex items-center">
                <span>{Math.round(stationData.WIND_SP || 0)}</span>
                <span className="text-base ml-1">{t('m/s')}</span>
            </div>
        </>)
    }

    const renderTempData = () => {
        if (!stationData) {
            return '------'
        }
        return (<>
            <span>{Math.round(stationData.TEMPERATURE || 0)}</span>
            <span className="text-base">°C</span>
        </>)
    }
    return (
        <div className="w-full sm:w-1/2 2xl:w-1/3 2xl:h-64 h-80 my-3">
            {isSnap && <Link to={`/site/${takeOffSite?.slug}`}/>}
            <div
                className="h-full m-3 from-black/80 dark:from-black/80 hover:from-transparent hover:scale-[102%] transition-all to-transparent rounded-xl flex flex-col justify-center items-center relative overflow-hidden shadow-black/50 dark:shadow-white/10 shadow-md cursor-pointer"
                style={{
                    backgroundImage: `linear-gradient(to top, var(--tw-gradient-stops)), url('/assets/images/${takeOffSite.image}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
                onClick={() => navigate(`/site/${takeOffSite?.slug}`)}
            >
                <div
                    className="text-2xl absolute top-0 left-0 flex bg-white/60 dark:bg-black/60 rounded-br-xl w-fit px-3 h-12 flex justify-center items-center shadow-inner shadow-lg">
                    <h1 className="text-zinc-800 dark:text-zinc-200 font-bold">{t(takeOffSite?.name) || 'N/A'}</h1>
                </div>
                <div className="absolute bottom-0 left-0 flex w-full h-12 shadow-lg px-5 pb-5">
                    <div className="text-xl font-bold text-zinc-200 flex items-center justify-between w-full">
                        <div className="flex">
                            {renderWindData()}
                        </div>
                        <div className="flex items-center">
                            {renderTempData()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TakeOffSiteBox;
