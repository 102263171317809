import DataBox from "../DataBox";
import {FaLocationArrow} from "react-icons/fa";
import {getWindDirection} from "../../utils";
import {useTranslation} from "react-i18next";

const WindBox = ({now, intervals}) => {
    const {t} = useTranslation();

    return (
        <DataBox title={t('Smer i brzina vetra')}
                 value={<div className="flex items-center">
                     <FaLocationArrow style={{transform: `rotate(${parseInt(now?.WIND_ANG) + 135 || 135}deg)`}}
                                      className='mr-2' size={24}/>
                     <span>{getWindDirection(parseInt(now?.WIND_ANG))} {now?.WIND_SP || 0} {t('m/s')}</span>
                 </div>}
                 subValue={`${t('Max gust')}: ${now?.WIND_GUST || 0} ${t('m/s')}`}
                 sizes="w-full sm:w-2/4"
                 uppercase
                 bottomLeft={
                     <div className="flex flex-col items-center">
                         <span className="text-zinc-700 dark:text-slate-200 text-xs font-bold text-center">15 {t('min')}</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center items-center flex">
                              <FaLocationArrow
                                  style={{transform: `rotate(${parseInt(intervals?.interval15?.["AVG(`WIND_ANG`)"]) + 135 || 135}deg)`}}
                                  className='mr-1' size={12}/>
                             {getWindDirection(parseInt(intervals?.interval15?.["AVG(`WIND_ANG`)"]))} {parseFloat(intervals?.interval15?.["AVG(`WIND_SP`)"] || 0).toFixed(2)} {t('m/s')}</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Max gust')}: {parseFloat(intervals?.interval15?.["AVG(`WIND_MAX`)"] || 0).toFixed(2)} {t('m/s')}</span>
                     </div>
                 }
                 bottomMiddle={
                     <div className="flex flex-col items-center">
                         <span className="text-zinc-700 dark:text-slate-200 text-xs font-bold text-center">30 {t('min')}</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center items-center flex">
                             <FaLocationArrow
                                 style={{transform: `rotate(${parseInt(intervals?.interval30?.["AVG(`WIND_ANG`)"]) + 135 || 135}deg)`}}
                                 className='mr-1' size={12}/>
                             {getWindDirection(parseInt(intervals?.interval30?.["AVG(`WIND_ANG`)"]))} {parseFloat(intervals?.interval30?.["AVG(`WIND_SP`)"] || 0).toFixed(2)} {t('m/s')}< /span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Max gust')}: {parseFloat(intervals?.interval30?.["AVG(`WIND_MAX`)"] || 0).toFixed(2)} {t('m/s')}</span>
                     </div>
                 }
                 bottomRight={
                     <div className="flex flex-col items-center">
                         <span className="text-zinc-700 dark:text-slate-200 text-xs font-bold text-center">60 {t('min')}</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center items-center flex">
                              <FaLocationArrow
                                  style={{transform: `rotate(${parseInt(intervals?.interval60?.["AVG(`WIND_ANG`)"]) + 135 || 135}deg)`}}
                                  className='mr-1' size={12}/>
                             {getWindDirection(parseInt(intervals?.interval60?.["AVG(`WIND_ANG`)"]))} {parseFloat(intervals?.interval60?.["AVG(`WIND_SP`)"] || 0).toFixed(2)} {t('m/s')}</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Max gust')}: {parseFloat(intervals?.interval60?.["AVG(`WIND_MAX`)"] || 0).toFixed(2)} {t('m/s')}</span>
                     </div>
                 }
        />
    )
}

export default WindBox;
