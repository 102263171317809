import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import StationDashboard from "./pages/StationDashboard";
import {WeatherDataContextProvider} from "./contexts/WeatherDataContextProvider";
import {useMediaQuery} from "react-responsive";
import {useEffect} from "react";
import i18n from './i18n';
import {I18nextProvider, useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {ThemeProvider} from "./contexts/ThemeContext";
import NotFound from "./pages/NotFound";
import ForecastProvider from "./contexts/ForecastContextProvider";


function App() {
    const systemPrefersDark = useMediaQuery({query: '(prefers-color-scheme: dark)'});
    const {t} = useTranslation();

    useEffect(() => {
        localStorage.setItem('systemPrefersDark', systemPrefersDark);
    }, [systemPrefersDark])

    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider>
                <WeatherDataContextProvider>
                    <ForecastProvider>
                        <Helmet>
                            <title>{t('Vazduhoplovni savez Srbije | Meteo')}</title>
                            <meta
                                name="description"
                                content={t('Vazduhoplovni savez Srbije | Meteo')}
                            />
                            <meta property="og:image" content="/assets/images/vss.jpeg"/>
                            <meta name="keywords"
                                  content="VSS, Vazduhoplovni savez Srbije, Vazduhoplovni Savez Srbije, Meteo"/>
                        </Helmet>
                        <main>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<HomePage/>}/>
                                    <Route path="/site/:takeoffSiteSlug" element={<StationDashboard/>}/>

                                    <Route path='*' element={<NotFound/>}/>
                                </Routes>
                            </BrowserRouter>
                        </main>
                    </ForecastProvider>
                </WeatherDataContextProvider>
            </ThemeProvider>
        </I18nextProvider>
    );
}

export default App;
