import dayjs from "dayjs";

require('dayjs/locale/sr')
require('dayjs/locale/sr-cyrl')


export const processPointData = (data, date, model, times, currentLanguage) => {
    const dates = Array.from({length: 3}, (_, i) => {
        return dayjs(date).add(i, 'day').locale(currentLanguage === 'src' ? 'sr-cyrl' : currentLanguage).format('ddd DD MMM');
    })

    times = times.map(time => dayjs(time).utc('z').local().format('HH:mm'));

    const d = [];
    const cumulusCloudBase = [];
    const realSurfaceTemperature = [];
    const realSurfaceDewpoint = [];
    const realMslPressure = [];
    const mslPressure = [];
    const surfaceTemperature = [];
    const surfaceDewpoint = [];

    data.hwcrit.forEach((item, index) => {
        d.push([index, Math.round(item)])
    })

    data.zsfclcl.forEach((item, index) => {
        data.zsfclcldif[index] > 0 ? cumulusCloudBase.push([index, Math.round(item), Math.round(data.zsfclcldif[index] + item)]) : cumulusCloudBase.push([index, Math.round(item), Math.round(item)])
    })

    data.real_sfctemp.forEach((item, index) => {
        realSurfaceTemperature.push([index, Math.round(item)])
    })

    data.real_sfcdewpt.forEach((item, index) => {
        realSurfaceDewpoint.push([index, Math.round(item)])
    })

    data.real_mslpress.forEach((item, index) => {
        realMslPressure.push([index, Math.round(item)])
    })

    data.mslpress.forEach((item, index) => {
        mslPressure.push(Math.round(item))
    })

    data.sfctemp.forEach((item, index) => {
        surfaceTemperature.push([index, Math.round(item)])
    })

    data.sfcdewpt.forEach((item, index) => {
        surfaceDewpoint.push([index, Math.round(item)])
    })

    for (let i = 0; i < mslPressure.length; i++) {
        if (0 === mslPressure[i][1]) {
            d[i][1] = null;
            cumulusCloudBase[i][1] = null;
            surfaceTemperature[i][1] = null;
            surfaceDewpoint[i][1] = null;
            mslPressure[i][1] = null;
        }
    }


    return {
        times,
        dates,
        d,
        cumulusCloudBase,
        realSurfaceTemperature,
        realSurfaceDewpoint,
        realMslPressure,
        mslPressure,
        surfaceTemperature,
        surfaceDewpoint,
        rawData: data
    }
}

export const b64toBlob = async(base64) =>
    await fetch(`${base64}`).then(res => res.blob())
