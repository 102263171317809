import DataBox from "../DataBox";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";

const HumidityBox = ({now, trend, all}) => {
    const {t} = useTranslation();

    const [minHumidity, setMinHumidity] = useState(0);
    const [minHumidityTime, setMinHumidityTime] = useState(dayjs().format('HH:mm'));
    const [maxHumidity, setMaxHumidity] = useState(0);
    const [maxHumidityTime, setMaxHumidityTime] = useState(dayjs().format('HH:mm'));
    useEffect(() => {
        if (all.length) {
            const minHumidityData = getMinHumidity();
            const maxHumidityData = getMaxHumidity();

            if(minHumidityData && maxHumidityData) {
                setMinHumidity(minHumidityData.humidity);
                setMinHumidityTime(minHumidityData.time);
                setMaxHumidity(maxHumidityData.humidity);
                setMaxHumidityTime(maxHumidityData.time);
            }
        }
    }, [all]);
    const getMinHumidity = () => {
        return all.filter(item => dayjs(item.SEND_TIME).isAfter(dayjs().startOf('day'))).map(item => ({
            time: dayjs(item.SEND_TIME).format('HH:mm'),
            humidity: parseFloat(item.MOIST).toFixed(2)
        }))?.sort((a, b) => a.humidity - b.humidity)[0]
    };
    const getMaxHumidity = () => {
        return all.filter(item => dayjs(item.SEND_TIME).isAfter(dayjs().startOf('day'))).map(item => ({
            time: dayjs(item.SEND_TIME).format('HH:mm'),
            humidity: parseFloat(item.MOIST).toFixed(2)
        }))?.sort((a, b) => b.humidity - a.humidity)[0]
    };

    return (
        <DataBox title={t('Rel. vlažnost')} unit="%"
                 value={now?.MOIST || 0}
                 subValue={`${t('Trend (1h)')}: ${trend?.diff_MOIST_hour || 0} %`}
                 sizes="w-1/2 sm:w-1/4"
                 uppercase
                 bottomLeft={
                     <div className="flex flex-col">
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Min')} {minHumidity}%</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{minHumidityTime}</span>
                     </div>
                 }
                 bottomRight={
                     <div className="flex flex-col">
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Max')} {maxHumidity}%</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{maxHumidityTime}</span>
                     </div>
                 }
        />
    )
}

export default HumidityBox;
