import TakeOffSiteBox from "../../components/TakeOffSiteBox";
import {FEATURED_TAKE_OFF_SITE, TAKE_OFF_SITES} from "../../constants";
import {useTranslation} from "react-i18next";
import LanguagePicker from "../../components/LanguagePicker";
import EUChampionshipSiteBox from "../../components/EUChampionshipSiteBox";

const HomePage = () => {
    const {t} = useTranslation();
    return (
        <div className="bg-zinc-100 dark:bg-slate-900 min-h-screen pb-10">
            <LanguagePicker/>
            <div className="container mx-auto px-4 flex flex-col">
                <img src="assets/images/logo.png" alt="logo"
                     className="p-2 mx-auto mb-10 lg:pt-10 dark:invert dark:brightness-0"/>
                {FEATURED_TAKE_OFF_SITE &&
                    <>
                <h1 className="text-center text-3xl font-bold text-zinc-800 dark:text-slate-100">{t('16th FAI PARAGLIDING EUROPEAN CHAMPIONSHIP')}</h1>
                <EUChampionshipSiteBox key={"EUChampionshipSiteBox"} takeOffSite={FEATURED_TAKE_OFF_SITE}/>
                </>
                }
                <h1 className="text-center text-3xl font-bold text-zinc-800 dark:text-slate-100 mt-10">{t('Odaberite poletište')}</h1>
                <div className="flex flex-wrap justify-center">
                    {TAKE_OFF_SITES.map((site, index) => (
                        <TakeOffSiteBox key={index} takeOffSite={site}/>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default HomePage;
