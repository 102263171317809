import DataBox from "../DataBox";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";

const CloudBaseBox = ({now, trend, all}) => {
    const {t} = useTranslation();

    const [minCloudBase, setMinCloudBase] = useState(0);
    const [minCloudBaseTime, setMinCloudBaseTime] = useState(dayjs().format('HH:mm'));
    const [maxCloudBase, setMaxCloudBase] = useState(0);
    const [maxCloudBaseTime, setMaxCloudBaseTime] = useState(dayjs().format('HH:mm'));
    useEffect(() => {
        if (all.length) {
            const minCloudBaseData = getMinCloudBase();
            const maxCloudBaseData = getMaxCloudBase();
            if(minCloudBaseData && maxCloudBaseData) {
                setMinCloudBase(minCloudBaseData.cloudBase);
                setMinCloudBaseTime(minCloudBaseData.time);
                setMaxCloudBase(maxCloudBaseData.cloudBase);
                setMaxCloudBaseTime(maxCloudBaseData.time);
            }
        }
    }, [all]);
    const getMinCloudBase = () => {
        return all.filter(item => dayjs(item.SEND_TIME).isAfter(dayjs().startOf('day'))).map(item => ({
            time: dayjs(item.SEND_TIME).format('HH:mm'),
            cloudBase: parseInt(item.CL_BASE)
        }))?.sort((a, b) => a.cloudBase - b.cloudBase)[0]
    };
    const getMaxCloudBase = () => {
        return all.filter(item => dayjs(item.SEND_TIME).isAfter(dayjs().startOf('day'))).map(item => ({
            time: dayjs(item.SEND_TIME).format('HH:mm'),
            cloudBase: parseInt(item.CL_BASE)
        }))?.sort((a, b) => b.cloudBase - a.cloudBase)[0]
    };

    return (
        <DataBox title={t('Baza oblaka')} unit="m"
                 value={now?.CLOUD || 0}
                 subValue={`${t('Trend (1h)')}: ${trend?.diff_CLOUD_hour || 0} m`}
                 sizes="w-1/2 sm:w-1/4"
                 uppercase
                 bottomLeft={
                     <div className="flex flex-col">
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Min')} {minCloudBase}m</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{minCloudBaseTime}</span>
                     </div>
                 }
                 bottomRight={
                     <div className="flex flex-col">
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Max')} {maxCloudBase}m</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{maxCloudBaseTime}</span>
                     </div>
                 }
        />
    )
}

export default CloudBaseBox;
