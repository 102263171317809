const Box = ({children, ...props}) => {
    const {sizes, customClasses, titlePosition = 'left', title, subtitle, uppercase, actions = []} = props;
    return (
        <div className={`${sizes} my-2`}>
            <div
                className={`bg-white dark:bg-slate-800 rounded-lg py-3 px-3 m-0 sm:m-2 ring-1 ring-slate-900/5 shadow-xl ${customClasses}`}>
                <div
                    className={`flex ${titlePosition === 'left' ? 'flex-row' : 'flex-row-reverse'} justify-between items-center`}>
                    <h2 className={`text-zinc-700 dark:text-white text-xl xl:text-lg text-center sm:text-left font-bold tracking-tight ${uppercase ? 'uppercase' : ''} text-${titlePosition}`}>{title}</h2>
                    <div className="flex gap-3 lg:gap-2">
                        {actions.map((action, index) => (
                            <div key={index} className="px-2 flex items-center text-lg cursor-pointer font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md p-1 dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]" onClick={action.onClick}>
                                {action.icon ? action.icon : action.name}
                                {action.title && <span className=" text-xs ml-1">{action.title}</span>}
                            </div>
                        ))}
                    </div>
                </div>
                {subtitle &&
                    <p className={`text-zinc-500 dark:text-slate-400 text-base font-medium tracking-tight text-center sm:text-left text-${titlePosition}`}>{subtitle}</p>
                }
                <div className="my-3">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Box;
