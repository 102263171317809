import {createContext, useState} from "react";
import dayjs from "dayjs";

export const WeatherDataContext = createContext({});

export const WeatherDataContextProvider = (props) => {
    const [stationData, setStationData] = useState(null);
    const [now, setNow] = useState(null);
    const [allData, setAllData] = useState([]);
    const [windAvg15, setWindAvg15] = useState([]);
    const [windAvg30, setWindAvg30] = useState([]);
    const [windAvg60, setWindAvg60] = useState([]);
    const [interval15, setInterval15] = useState(null);
    const [interval30, setInterval30] = useState(null);
    const [interval60, setInterval60] = useState(null);
    const [trend, setTrend] = useState(null);

    const getAllDataInterval = async (interval = 60) => {
        if(!allData.length) return;
        return allData.filter(data => (
            dayjs(data.SEND_TIME).isAfter(dayjs().subtract(interval, 'minutes'))
        ));
    }

    return (
        <WeatherDataContext.Provider value={{
            stationData,
            setStationData,
            allData,
            setAllData,
            windAvg15,
            setWindAvg15,
            windAvg60,
            setWindAvg60,
            windAvg30,
            setWindAvg30,
            interval15,
            setInterval15,
            interval30,
            setInterval30,
            interval60,
            setInterval60,
            trend,
            setTrend,
            getAllDataInterval,
            now,
            setNow
        }}>
            {props.children}
        </WeatherDataContext.Provider>
    );
};
