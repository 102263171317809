import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import translationEN from './assets/locales/en/translation.json';
import translationSR from './assets/locales/sr/translation.json'
import translationSRC from './assets/locales/src/translation.json';
// the translations
const resources = {
    en: {
        translation: translationEN
    },
    sr: {
        translation: translationSR
    },
    src: {
        translation: translationSRC
    }
};

i18n
    // .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('lang') || 'en',
        fallbackLng: "sr", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
