
import {useTranslation} from "react-i18next";

const StationInfoBox = ({takeOffSite, stationData, now}) =>{
    const {t} = useTranslation();

    return (
        <div
            className='flex flex-col sm:border-2 dark:sm:border-white sm:border-slate-900 p-1 m-2 items-center px-3'>
            <span className="text-slate-900 dark:text-zinc-200 text-3xl font-black">{t(takeOffSite?.name)}</span>
            <div>
                <span className="text-slate-900 dark:text-zinc-200 text-xs font-bold text-center mr-3">{t('LAT / LONG')}: {stationData?.LAT} / {stationData?.LONG}</span>
                <span
                    className="text-slate-900 dark:text-zinc-200 text-xs font-bold text-center">{t('AMSL')}: <b>{stationData?.ALT}m</b></span>
            </div>
            <div>
                <span className="text-slate-900 dark:text-zinc-200 text-xs font-bold text-center">{t('Podaci osveženi')}: {now?.SEND_TIME || stationData?.last_update}</span>
            </div>
        </div>
    )
}

export default StationInfoBox;
