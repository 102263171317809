import {MapContainer, Marker, TileLayer, useMapEvents} from "react-leaflet";
import L from "leaflet";
import {useMemo, useRef, useState} from "react";
import {useForecastContext} from "../../contexts/ForecastContextProvider";

const height = 43;
const width = 43;

const TakeoffPositionMap = ({station, fullHeight = false, forecast = false}) => {
    const originalPosition = [station?.LAT || 0, station?.LONG || 0];
    const {position, setPosition} = useForecastContext()


    const DraggableMarker = () => {
        const [markerPosition, setMarkerPosition] = useState(position)
        const markerRef = useRef(null)

        const map = useMapEvents({
            click: (e) => {
                setPosition(map.mouseEventToLatLng(e.originalEvent));
            },
        })

        const eventHandlers = useMemo(
            () => ({
                dragend() {
                    const marker = markerRef.current
                    if (marker != null) {
                        setMarkerPosition(marker.getLatLng())
                        setPosition(marker.getLatLng())
                    }
                },
            }),
            [],
        )

        return (
            <Marker
                draggable={true}
                eventHandlers={eventHandlers}
                position={markerPosition}
                ref={markerRef}
                icon={L.icon({
                    iconUrl: '/assets/images/weather-marker-icon.png',
                    iconRetinaUrl: '/assets/images/weather-marker-icon.png',
                    iconSize: [height, width],
                    iconAnchor: [height / 2, height + 9],
                })}
            >
            </Marker>
        )
    }

    if (originalPosition[0] && originalPosition[1]) {
        return (
            <MapContainer
                className={`${fullHeight ? 'h-96 md:h-full md:min-h-[calc(100vh-90px)]' : 'h-96'} max-h-full w-full z-10`} center={originalPosition}
                zoom={14} scrollWheelZoom={false}>
                <TileLayer
                    maxZoom={16}
                    attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                    url='http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                {!forecast &&
                    <Marker position={originalPosition} icon={L.icon({
                        iconUrl: '/assets/images/marker-icon.png',
                        iconRetinaUrl: '/assets/images/marker-icon.png',
                        iconSize: [height, width],
                        iconAnchor: [height / 2, height + 9],
                    })}>
                    </Marker>
                }
                {forecast && <DraggableMarker/>}
            </MapContainer>
        )
    }

}

export default TakeoffPositionMap
