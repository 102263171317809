const DataBox = ({children, ...props}) => {
    const {
        sizes,
        customClasses,
        titlePosition = 'left',
        title,
        uppercase,
        value,
        unit,
        subValue,
        bottomLeft,
        bottomMiddle,
        bottomRight
    } = props;
    return (
        <div className={`${sizes} self-stretch my-2`}>
            <div
                className={`bg-white dark:bg-slate-800 rounded-lg p-3 mx-2 ring-1 ring-slate-900/5 shadow-xl h-full ${customClasses}`}>
                <h2 className={`text-zinc-700 dark:text-white text-lg text-center sm:text-left font-bold ${uppercase ? 'uppercase' : ''} text-${titlePosition} min-h-[20%]`}>{title}</h2>
                <div className="flex flex-col flex-1 justify-center items-center py-3 sm:py-5 min-h-[45%]">
                    <h1 className="text-2xl font-bold text-zinc-700 dark:text-white">
                        {value}<span className="text-base font-bold"> {unit}</span>
                    </h1>
                    <span className="text-sm text-zinc-700 dark:text-white leading-none">{subValue}</span>
                </div>
                <div className="flex justify-between flex-1 items-center px-0 sm:px-1 mt-0 sm:mt-4 mb-3 min-h-[30%]">
                    {bottomLeft}
                    {bottomMiddle}
                    {bottomRight}
                </div>
            </div>
        </div>
    );
};

export default DataBox;
