// export const FEATURED_TAKE_OFF_SITE =
//     {
//         id: 26,
//         name: 'D01',
//         slug: 'd01',
//         image: 'nis.jpeg',
//         featuredLogo: 'EuChamp'
//     }
    export const FEATURED_TAKE_OFF_SITE = null
export const TAKE_OFF_SITES = [
    {
        id: 30,
        name: 'Sportski aerodrom Veliki Radinci',
        slug: 'lysm',
        image: 'lysm.webp'
    },
    {
        id: 16,
        name: 'Vršac-Kula',
        slug: 'vrsac-kula',
        image: 'vrsac.webp'
    },
    {
        id: 14,
        name:'Zlatibor-Vojska',
        slug: 'zlatibor-vojska',
        image:'zlatibor.webp'
    },
    {
        id: 24,
        name:'Stolovi',
        slug: 'stolovi',
        image:'stolovi.webp'
    },
    {
        id: 25,
        name:'Rajac',
        slug: 'rajac',
        image:'rajac.webp'
    },
    {
        id: 26,
        name: 'Oreovac - Niš',
        slug: 'oreovac-nis',
        image: 'nis.webp',
    },
    {
        id: 27,
        name: 'Šajkaš - Titelski breg',
        slug: 'titelski-breg',
        image: 'titleski-breg.webp',
    },
    {
        id: 33,
        name: 'Fruška gora',
        slug: 'fruska-gora',
        image: 'sakotinac.webp',
    },
    {
        id: 29,
        name:'Drvar-Kruškovac',
        slug: 'drvar-kruskovac',
        image:'drvar-kruskovac.webp'
    }
]
