const NotFound = () => {
    return (
    <div className="max-h-screen" style={{
        width: '100vw',
        height: '100vh',
        position: 'relative',
    }}>
        <img style={{
            left:0,
            right:0,
            marginLeft: 'auto',
            marginRight: 'auto',
        }} src="assets/images/logo.png" alt="logo"
             className="p-2 mx-auto mb-10 lg:pt-10 absolute dark:invert dark:brightness-0"/>
        <img src="/assets/images/404.webp" alt="404" className="object-cover w-full h-full" />
    </div>
    )
}

export default NotFound;
