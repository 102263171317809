import {useParams} from "react-router-dom";
import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getStationData, getAllData, getTrend, getInterval, getNow} from "../../services/api";
import {WeatherDataContext} from "../../contexts/WeatherDataContextProvider";
import {FEATURED_TAKE_OFF_SITE, TAKE_OFF_SITES} from "../../constants";
import {WindRoseChart} from "../../components/WindRoseChart";
import Box from "../../components/Box";
import {useInterval} from "../../hooks/useInterval";
import TemperatureBox from "../../components/TemperatureBox";
import WindBox from "../../components/WindBox";
import PressureBox from "../../components/PressureBox";
import HumidityBox from "../../components/HumidityBox";
import CloudBaseBox from "../../components/CloudBaseBox";
import DewPointBox from "../../components/DewPointBox";
import SolarBox from "../../components/SolarBox";
import TakeoffPositionMap from "../../components/TakeoffPositionMap";
import WindBarbChart from "../../components/WindBarbChart";
import Highcharts from "highcharts/highstock";
import {DarkUnicaOptions} from "../../utils/DarkUnicaOptions";
import HcMore from "highcharts/highcharts-more";
import {isDesktop, isIOS, isMobile, isTablet, useMobileOrientation} from "react-device-detect";
import StationInfoBox from "../../components/StationInfoBox";
import {useTranslation} from "react-i18next";
import LanguagePicker from "../../components/LanguagePicker";
import {Helmet} from "react-helmet";
import SkewTDiagram from "../../components/SkewT";
import IonRangeSlider from "react-ion-slider";
import {useForecastContext} from "../../contexts/ForecastContextProvider";
import {MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos} from "react-icons/md";
import {FaLocationArrow, FaMapMarkedAlt} from "react-icons/fa";
import {TiWeatherWindyCloudy} from "react-icons/ti";
import PointTable from "../../components/PointTable";
import {TbPointerPin} from "react-icons/tb";
import {useScreenshot} from "use-react-screenshot";
import {b64toBlob} from "../../components/PointTable/utils";
import {RiScreenshot2Fill} from "react-icons/ri";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from "dayjs";
import {getWindDirection} from "../../utils";

HcMore(Highcharts);
Highcharts.setOptions(DarkUnicaOptions());
const StationDashboard = () => {
    const {t} = useTranslation();
    const {isLandscape} = useMobileOrientation()

    const {takeoffSiteSlug} = useParams();
    const [takeOffSite, setTakeOffSite] = useState()
    const [loading, setLoading] = useState(true);
    const [skewTVisible, setSkewTVisible] = useState(false);
    const [pointVisible, setPointVisible] = useState(false);
    const {availableTime, date, time, setDate, setTime, setPosition, mapVisible, setMapVisible} = useForecastContext()
    const {
        stationData,
        setStationData,
        allData,
        setAllData,
        interval15,
        setInterval15,
        interval30,
        setInterval30,
        interval60,
        setInterval60,
        trend,
        setTrend,
        now,
        setNow,
    } = useContext(WeatherDataContext)
    const navigate = useNavigate();
    const pointTableRef = useRef(null);
    const pointTableScreenshotButtonRef = useRef(null);
    const skewTRef = useRef(null);
    const [screenshot, setScreenshot] = useState(null)
    const [, takeScreenshot] = useScreenshot()
    // const takePointTableScreenshot = async () => setScreenshot(await takeScreenshot(pointTableRef.current))
    const triggerScreenshot = async (type) => {
        const screenshot = await takeScreenshot(type === 'point' ? pointTableRef.current : skewTRef.current, {
            dpi: 144,
            scale: 2,
            scrollX: 0,
            scrollY: -window.scrollY,
        })
        const blob = await b64toBlob(screenshot)
        setScreenshot(blob)
        if (!isIOS) {
            await copyScreenshotToClipboard(blob)
            await shareScreenshot(blob, type)
        } else {
            toast.info(t('Screenshot taken, press same button again to share it'))
        }
    }
    const shareScreenshot = async (blob, type) => {
        const file = new File([blob], `${takeOffSite?.name}-${dayjs().format('DDMMYYYY')}-${type}.png`, {type: blob.type,})
        if (navigator.canShare && navigator.canShare({files: [file]})) {
            try {
                await navigator.share({
                    files: [file],
                })
                setScreenshot(null)
            } catch (e) {
            }
        } else {
            // alert('Web Share API not supported')
        }
    }

    const copyScreenshotToClipboard = async (blob) => {
        try {
            await navigator.clipboard.write([
                new window.ClipboardItem({
                    'image/png': blob
                })
            ]);
            toast.info(t('Screenshot copied to clipboard'), {
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
            });
            setScreenshot(null)
        } catch (e) {
            // alert(e.message)
        }
    }
    const getData = async () => {
        const [stationData, allData, now, interval15, interval30, interval60, trend] = await Promise.all([getStationData(takeOffSite.id), getAllData(takeOffSite.id), getNow(takeOffSite.id), getInterval(takeOffSite.id, 15), getInterval(takeOffSite.id, 30), getInterval(takeOffSite.id, 60), getTrend(takeOffSite.id)]).catch(() => {
            window.location.reload()
        });
        setStationData(stationData)
        setAllData(allData)
        setNow(now)
        setInterval15(interval15)
        setInterval30(interval30)
        setInterval60(interval60)
        setTrend(trend)
        setPosition({lat: stationData.LAT, lng: stationData.LONG})
    }
    useEffect(() => {
        const takeOffSiteLocal = TAKE_OFF_SITES.find(site => site.slug === takeoffSiteSlug) ? TAKE_OFF_SITES.find(site => site.slug === takeoffSiteSlug) : FEATURED_TAKE_OFF_SITE?.slug === takeoffSiteSlug ? FEATURED_TAKE_OFF_SITE : null;
        setTakeOffSite(takeOffSiteLocal);
        if (!takeOffSiteLocal) {
            navigate('/')
        }
    }, [takeoffSiteSlug]);

    useEffect(() => {
        if (takeOffSite) {
            (async () => {
                await getData();
                setLoading(false)
            })()
        }
    }, [takeOffSite])

    useInterval(async () => {
        if (takeOffSite) {
            const now = await getNow(takeOffSite.id);
            setNow(now);
        }
    }, 60000)


    if (loading) {
        return (<div
            className="flex justify-center items-center h-screen bg-zinc-100 dark:bg-slate-900 w-full justify-center items-center">
            <div className="w-1/2 flex justify-center">
                <img src="/assets/images/logo.png" className=" animate-pulse dark:invert dark:brightness-0"
                     alt="loading"/>
            </div>
        </div>)
    }
    return (<>
        <Helmet>
            <title>{t(takeOffSite?.name)} | {t('Vazduhoplovni savez Srbije | Meteo')}</title>
            <meta
                name="description"
                content={`${t(takeOffSite?.name)} | ${t('Vazduhoplovni savez Srbije | Meteo')}`}
            />
            <meta name="keywords"
                  content={`VSS, Vazduhoplovni savez Srbije, Vazduhoplovni Savez Srbije, Meteo, ${takeOffSite?.name}`}/>
            <meta property="og:url" content={`https://meteo.vss.rs/site/${takeOffSite?.slug}`}/>
            <meta property="og:image" content={`/assets/images/${takeOffSite?.image}`}/>
            <meta property="twitter:image" content={`/assets/images/${takeOffSite?.image}`}/>
        </Helmet>
        <div className={`bg-zinc-100 dark:bg-slate-900 min-h-screen ${skewTVisible || pointVisible ? 'hidden' : ''}`}>
            <LanguagePicker/>
            <div
                className="bg-gray-200 dark:bg-slate-800 pl-3 pt-3 md:pt-0 flex flex-col md:flex-row items-center ring-1 ring-slate-900/5 drop-shadow-lg justify-between">
                <img src="/assets/images/logo.png" alt="logo"
                     className="mr-5 p-2 dark:invert dark:brightness-0 cursor-pointer"
                     onClick={() => navigate('/')}/>
                <div>
                    <StationInfoBox takeOffSite={takeOffSite} stationData={stationData} now={now}/>
                </div>
            </div>
            <div className="flex p-2 mt-5 flex-col md:flex-row flex-wrap">
                <Box sizes="w-full xl:w-1/3" customClasses="my-2 relative" uppercase title={t('Lokacija')} actions={[{
                    name: 'SkewT', onClick: () => {
                        setSkewTVisible(true)
                    }, icon: <TiWeatherWindyCloudy/>,
                    title: t('Skew-T')
                }, {
                    name: 'PointTable', onClick: () => {
                        setPointVisible(true)
                    }, icon: <TbPointerPin/>,
                    title: t('Point')
                }]}>
                    <TakeoffPositionMap station={stationData}/>
                    <div className={`absolute bottom-[25px] left-[25px] z-40 flex flex-col gap-0.5  p-1 items-center ${now?.WIND_SP > 7 && 'border-2 border-red-700'}`}>
                        <FaLocationArrow style={{transform: `rotate(${parseInt(now?.WIND_ANG) + 135 || 135}deg)`}}
                                         className='mr-1' color={now?.WIND_SP > 7 ? 'red' : '#000'} size={20}/>
                        <span className={`font-bold ${now?.WIND_SP > 7 && 'text-red-700'}`}>{now?.WIND_SP || 0} {t('m/s')}</span>

                    </div>
                </Box>
                <div className="flex p-0 xl:p-2 flex-wrap flex-1">
                    <WindBox now={now} all={allData} intervals={{interval15, interval30, interval60}}/>
                    <TemperatureBox now={now} trend={trend} all={allData}/>
                    <PressureBox now={now} trend={trend} all={allData}/>
                    <HumidityBox now={now} trend={trend} all={allData}/>
                    <CloudBaseBox now={now} trend={trend} all={allData}/>
                    <DewPointBox now={now} trend={trend} all={allData}/>
                    <SolarBox now={now} trend={trend} all={allData}/>
                </div>
            </div>
            <div className="flex flex-wrap relative p-2">
                <Box sizes="w-full xl:w-1/3" uppercase title={t('Smer i snaga vetra')}
                     subtitle={`(${t('30 min avg')})`}>
                    <WindRoseChart/>
                </Box>
                <Box sizes="w-full xl:w-2/3" uppercase title={t('Brzina vetra, Max gust & Temperatura')}
                     subtitle={`${t('Interval 8h')} (${t(isMobile ? '30 min avg' : '15 min avg')})`}>
                    <WindBarbChart/>
                </Box>
            </div>
        </div>
        {skewTVisible && !pointVisible && <div
            className="w-full h-full max-h-full min-h-screen flex bg-zinc-100 z-50 flex items-center flex-col">
            <div className="w-full bg-zinc-100 dark:bg-slate-900">
                <div
                    className="bg-gray-200 dark:bg-slate-800 pl-3 pt-3 md:pt-0 flex flex-col xl:flex-row items-center ring-1 ring-slate-900/5 drop-shadow-lg justify-between">
                    <div
                        className="xl:hidden absolute top-5 right-0 mr-5 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                        onClick={() => {
                            setSkewTVisible(false)
                        }}>x
                    </div>
                    <div
                        className="xl:hidden absolute top-5 right-10 p-1.5 mr-5 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                        onClick={() => {
                            setMapVisible(!mapVisible)
                        }}>
                        <FaMapMarkedAlt/>
                    </div>
                    <img src="/assets/images/logo.png" alt="logo"
                         className="mr-5 p-2 dark:invert dark:brightness-0 cursor-pointer  w-1/2 xl:w-2/12"/>
                    <div className="flex flex-row items-center mt-5 md:mt-0">
                        <div
                            className="mr-2 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                            onClick={() => {
                                setDate(date.subtract(1, 'day'))
                            }}>
                            <MdOutlineArrowBackIosNew/>
                        </div>
                        <div
                            className="text-slate-900 dark:text-zinc-200 font-bold text-lg">{date.format('DD.MM.YYYY')}</div>
                        <div
                            className="xl:mr-5 ml-2 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                            onClick={() => {
                                setDate(date.add(1, 'day'))
                            }}>
                            <MdOutlineArrowForwardIos/>
                        </div>
                        <div
                            title={t('Point forecast')}
                            className="xl:mr-2 ml-5 xl:ml-0 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                            onClick={() => {
                                setSkewTVisible(false)
                                setPointVisible(true)
                            }}>
                            <TbPointerPin size={23}/>
                        </div>
                        <div
                            title={t('Screenshot')}
                            className="xl:mr-5 ml-2 xl:ml-0 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                            onClick={() => {
                                screenshot ? shareScreenshot(screenshot, 'skewt') : triggerScreenshot('skewt')

                            }}>
                            <RiScreenshot2Fill size={23}/>
                        </div>
                    </div>
                    <div className="w-full px-5 xl:px-0 xl:mr-5 mb-3 time-slider dark:dark-time-slider">
                        <IonRangeSlider grid grid_snap
                                        values={availableTime}
                                        from={time?.from}
                                        onFinish={(e) => {
                                            setTime({from: e.from, value: e.from_value})
                                        }}
                                        skin="round"/>
                    </div>
                    <div/>
                    <div
                        className="hidden p-3 mr-5 w-[34px] h-[34px] xl:flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                        onClick={() => {
                            setSkewTVisible(false)
                        }}>x
                    </div>
                </div>
            </div>
            <div className="flex xl:flex-row flex-col w-full max-h-[1160px] flex-1 h-full">
                {mapVisible && <div className="xl:w-1/3 w-full">
                    <TakeoffPositionMap station={stationData} fullHeight={isDesktop || (isTablet && isLandscape)}
                                        forecast/>
                </div>}
                <div className="xl:w-2/3 xl:pr-5 w-full ml-1 bg-white mr-2">
                    <SkewTDiagram ref={skewTRef}/>
                </div>
            </div>

        </div>}

        {pointVisible && !skewTVisible && <div
            className="w-full h-full max-h-full min-h-screen flex bg-zinc-100 z-50 flex justify-center items-center flex-col">
            <div className="w-full bg-zinc-100 dark:bg-slate-900">
                <div
                    className="bg-gray-200 dark:bg-slate-800 pl-3 pt-3 md:pt-0 flex flex-col xl:flex-row items-center ring-1 ring-slate-900/5 drop-shadow-lg justify-between">
                    <div
                        className="xl:hidden absolute top-5 right-0 mr-5 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                        onClick={() => {
                            setPointVisible(false)
                        }}>x
                    </div>
                    <div
                        className="xl:hidden absolute top-5 right-10 p-1.5 mr-5 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                        onClick={() => {
                            setMapVisible(!mapVisible)
                        }}>
                        <FaMapMarkedAlt/>
                    </div>
                    <img src="/assets/images/logo.png" alt="logo"
                         className="mr-5 p-2 dark:invert dark:brightness-0 cursor-pointer  w-1/2 xl:w-2/12 py-2 lg:py-5"/>
                    <div className="flex flex-row items-center mb-5 xl:mb-0 mt-5 md:mt-0 flex-1">
                        <div
                            className="mr-2 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                            onClick={() => {
                                setDate(date.subtract(1, 'day'))
                            }}>
                            <MdOutlineArrowBackIosNew/>
                        </div>
                        <div
                            className="text-slate-900 dark:text-zinc-200 font-bold text-lg">{date.format('DD.MM.YYYY')}</div>
                        <div
                            className="ml-2 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                            onClick={() => {
                                setDate(date.add(1, 'day'))
                            }}>
                            <MdOutlineArrowForwardIos/>
                        </div>
                        <div
                            title={t('Skew-T Diagram')}
                            className="xl:mr-2 ml-5 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                            onClick={() => {
                                setPointVisible(false)
                                setSkewTVisible(true)
                            }}>
                            <TiWeatherWindyCloudy size={23}/>
                        </div>
                        <div
                            title={t('Screenshot')}
                            className="xl:mr-5 ml-2 xl:ml-0 w-[34px] h-[34px] flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                            onClick={() => {
                                screenshot ? shareScreenshot(screenshot, 'point') : triggerScreenshot('point')
                            }}
                            ref={pointTableScreenshotButtonRef}
                        >
                            <RiScreenshot2Fill size={23}/>
                        </div>
                    </div>
                    <div
                        className="hidden p-3 mr-5 w-[34px] h-[34px] xl:flex justify-center items-center font-bold text-slate-900 dark:text-zinc-200 border-slate-900 dark:border-zinc-200 border-2 rounded-md cursor-pointer dark:hover:text-[#006cfa] dark:hover:border-[#006cfa]"
                        onClick={() => {
                            setPointVisible(false)
                        }}>x
                    </div>
                </div>
            </div>
            <div className="flex xl:flex-row flex-col w-full flex-1 h-full bg-white dark:bg-slate-800">
                {mapVisible && <div className="xl:w-1/3 w-full">
                    <TakeoffPositionMap station={stationData} fullHeight={isDesktop || (isTablet && isLandscape)}
                                        forecast/>
                </div>}
                <div
                    className="xl:w-2/3 w-[calc(100% - 20px)] overflow-auto p-2 dark:p-0 dark:bg-slate-800 content-center flex-1">
                    <PointTable ref={pointTableRef}/>
                </div>
            </div>

        </div>}
        <ToastContainer/>
    </>);
};

export default StationDashboard;
