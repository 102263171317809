import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {ThemeContext} from "../../contexts/ThemeContext";
import ModelPicker from "../ModelPicker";

const LanguagePicker = ({start}) => {
    const {i18n, t} = useTranslation();
    const {theme, setTheme} = useContext(ThemeContext)

    return (
        <div className={`flex justify-between p-4`}>
            <div>
                <label htmlFor="toggle-example-checked" className="flex items-center cursor-pointer relative">
                    <input type="checkbox" id="toggle-example-checked" className="sr-only" checked={theme === 'dark'}
                           onChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')}/>
                    <div className="toggle-bg bg-blue-500/40 border-2 border-blue-400/40 h-6 w-11 rounded-full"></div>
                    <span
                        className="ml-3 text-gray-900 dark:text-white text-sm font-medium">{theme === 'dark' ? t('Tamna tema') : t('Svetla tema')}</span>
                </label>
            </div>
            <ModelPicker/>
            <div>
                <span
                    className={`text-base sm:text-sm dark:text-zinc-200 px-3 py-1 rounded-3xl cursor-pointer ${i18n.language === 'src' ? 'text-white font-bold bg-blue-500/80 dark:bg-blue-500/40' : 'text-slate-800'}`}
                    onClick={() => {
                        i18n.changeLanguage('src', localStorage.setItem('lang', 'src'))

                    }}>Српски</span>
                <span
                    className={`text-base sm:text-sm  dark:text-zinc-200 px-3 py-1 rounded-3xl cursor-pointer ${i18n.language === 'sr' ? 'text-white font-bold bg-blue-500/80 dark:bg-blue-500/40' : 'text-slate-800'}`}
                    onClick={() => {
                        i18n.changeLanguage('sr', localStorage.setItem('lang', 'sr'))

                    }}>Srpski</span>
                <span
                    className={`text-base sm:text-sm  dark:text-zinc-200 px-3 py-1 rounded-3xl cursor-pointer ${i18n.language === 'en' ? 'text-white font-bold bg-blue-500/80 dark:bg-blue-500/40' : 'text-slate-800'}`}
                    onClick={() => {
                        i18n.changeLanguage('en', localStorage.setItem('lang', 'en'))
                    }}>English</span>
            </div>
        </div>
    )
}

export default LanguagePicker;
