import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import dayjs from "dayjs";

const ForecastContext = createContext();

const ForecastProvider = ({children}) => {
    const availableTime = ['08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [position, setPosition] = useState(null);
    const [mapVisible, setMapVisible] = useState(true);
    const [model, setModel] = useState('EUROPE');

    const roundToNearest30 = useCallback((date = new Date()) => {
        const minutes = 30;
        const ms = 1000 * 60 * minutes;

        // 👇️ replace Math.round with Math.ceil to always round UP
        return new Date(Math.round(date.getTime() / ms) * ms);
    },[])

    useEffect(()=>{
        const currentTime = dayjs(roundToNearest30()).format('HH:mm');
        const availableTimeIndex = availableTime.indexOf(currentTime);
        if(availableTimeIndex === -1) {
            setTime({
                from: availableTime.length - 1,
                value: availableTime[availableTime.length - 1]
            })
        } else {
            setTime({
                from: availableTimeIndex,
                value: currentTime
            })
        }
        setDate(dayjs())
    },[])

    const data = {
        availableTime,
        date,
        time,
        position,
        setDate,
        setTime,
        setPosition,
        mapVisible,
        setMapVisible,
        model,
        setModel
    };

    return <ForecastContext.Provider value={data}>{children}</ForecastContext.Provider>;
};

export const useForecastContext = () => {
    const context = useContext(ForecastContext);

    if (context === null) {
        throw new Error('useForecastContext must be used inside ForecastProvider!');
    }

    return context;
};

export default ForecastProvider;
