import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {useContext, useMemo, useState} from "react";
import {WeatherDataContext} from "../../contexts/WeatherDataContextProvider";
import highchartsWindbarb from 'highcharts/modules/windbarb';
import highchartsDataGrouping from 'highcharts/modules/datagrouping';
import utc from 'dayjs/plugin/utc'

import dayjs from "dayjs";
import {getWindDirection} from "../../utils";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

highchartsDataGrouping(Highcharts);
highchartsWindbarb(Highcharts);
dayjs.extend(utc)
const WindBarbChart = () => {
    const {t} = useTranslation();

    const {allData, getAllDataInterval} = useContext(WeatherDataContext);
    const [intervalData, setIntervalData] = useState([]);
    const systemPrefersDark = localStorage.getItem("color-theme") === 'dark';

    useMemo(() => {
        if (allData.length > 0) {
            getAllDataInterval(480).then(data => {
                setIntervalData(data.filter((item, index) =>  isMobile ? index % 30 === 0 : index % 15 === 0).sort(function (a, b) {
                    return new Date(a.SEND_TIME) - new Date(b.SEND_TIME)
                }));
            });
        }
    }, [allData]);

    let options = [];
    options = {
        title: {
            text: ''
        },

        xAxis: {
            type: 'datetime',
            useUTC: true,
            offset: 40,
            startOnTick: false,
            endOnTick: false,
        },

        plotOptions: {
            series: {
                pointPlacement: 'on',
                animation: {
                    duration: 1500,
                    colorByPoint: true
                },
                threshold: null,

            }
        },
        yAxis: [{
            title: {
                text: isMobile ? '' : `<span style="font-size: 10px">${t('Brzina vjetra')}</span>`,
            },
            labels: {
                format: `{value} ${t('m/s')}`,
                style: {
                    fontSize: '10px'
                },
                x: -3
            },
            plotLines: [{
                value: 0,
                color: '#BBBBBB',
                width: 0,
                zIndex: 2
            }],
            maxPadding: 0.3,
            minRange: 0.2,
            tickInterval: 0.4,
            gridLineColor: 'rgba(128, 128, 128, 0.1)'
        }, {
            allowDecimals: true,
            tickInterval: 1,
            title: {
                text: '°C',
                offset: 0,
                align: 'high',
                rotation: 0,
                style: {
                    fontSize: '10px',
                    color: Highcharts.getOptions().colors[1]
                },
                textAlign: 'left',
                x: 3,
                y: -3
            },
            labels: {
                style: {
                    fontSize: '10px',
                    color: Highcharts.getOptions().colors[1]
                },
                y: -3,
                x: 3
            },
            gridLineWidth: 0,
            opposite: true,
            showLastLabel: false,
            startOnTick: false,
            endOnTick: false
        }],

        legend: {
            enabled: true
        },

        series: [{
            type: 'windbarb',
            data: intervalData.map(data => {
                return [dayjs.utc(data.SEND_TIME).valueOf(), parseFloat(data.WIND_SP), parseInt(data.WIND_ANG)]
            }),
            name: t('Smjer vjetra'),
            color: Highcharts.getOptions().colors[0],
            showInLegend: false,
            tooltip: {
                valueSuffix: ` ${t('m/s')}`,
                valueDecimals: 2,
            },
        }, {
            type: 'spline',
            keys: ['x', 'y'], // rotation is not used here
            data: intervalData.map(data => {
                return [dayjs.utc(data.SEND_TIME).valueOf(), parseFloat(data.WIND_SP)]
            }),
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: true
                    }
                }
            },
            color: Highcharts.getOptions().colors[systemPrefersDark ? 6 : 0],

            name: t('Brzina vjetra'),
            tooltip: {
                valueSuffix: ` ${t('m/s')}`,
            },
            states: {
                inactive: {
                    opacity: 1
                }
            },
            zIndex: 3,

        }, {
            type: 'spline',
            keys: ['x', 'y'], // rotation is not used here
            data: intervalData.map(data => {
                return [dayjs.utc(data.SEND_TIME).valueOf(), parseFloat(data.WIND_GUST)]
            }),
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: true
                    }
                }
            },
            name: t('Max gust'),
            tooltip: {
                valueSuffix: ` ${t('m/s')}`,
            },
            zIndex: 2,
            color: Highcharts.getOptions().colors[systemPrefersDark ? 2 : 1],

        }, {
            type: 'area',
            keys: ['x', 'y'],
            data: intervalData.map(data => {
                return [dayjs.utc(data.SEND_TIME).valueOf(), parseFloat(data.TEMP)]
            }),
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: true
                    }
                }
            },

            name: t('Temperatura'),
            tooltip: {
                valueSuffix: ' °C'
            },
            zIndex: 1,
            yAxis: 1,
            color: Highcharts.getOptions().colors[systemPrefersDark ? 11 : 11],
            fillColor: {
                linearGradient: {x1: 0, x2: 0, y1: 0.05, y2: 1},
                stops: [
                    [0, Highcharts.getOptions().colors[systemPrefersDark ? 11 : 11]],
                    [
                        1,
                        Highcharts.color(Highcharts.getOptions().colors[6])
                            .setOpacity(0.1).get()
                    ]
                ]
            },
        }],
        tooltip: {
            formatter: function () {
                let s = [];
                s.push(`<span style="font-size: 10px;">${dayjs.utc(this.x).format('DD.MM.YYYY HH:mm')}</span><br>`)
                this.points.forEach(function (point, i) {
                    if (point.series.initialType === 'windbarb') {
                        s.push(`<span style="color: ${point.color}">●</span> ${point.series.name}: <b>${getWindDirection(point.point?.options?.direction)}</b><br><span>`);
                    } else {
                        s.push(`<span style="color: ${point.color}">●</span> ${point.series.name}: <b>${point.y || 0} ${point?.series?.tooltipOptions?.valueSuffix || ''}</b><br><span>`);

                    }
                });

                return s.join('');
            },
            shared: true,
            useHTML: true
        },
    };

    return <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />
}

export default WindBarbChart;
