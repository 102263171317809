import DataBox from "../DataBox";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";

const DewPointBox = ({now, trend, all}) => {
    const {t} = useTranslation();

    const [minDewPoint, setMinDewPoint] = useState(0);
    const [minDewPointTime, setMinDewPointTime] = useState(dayjs().format('HH:mm'));
    const [maxDewPoint, setMaxDewPoint] = useState(0);
    const [maxDewPointTime, setMaxDewPointTime] = useState(dayjs().format('HH:mm'));
    useEffect(() => {
        if (all.length) {
            const minDewPointData = getMinDewPoint();
            const maxDewPointData = getMaxDewPoint();
            if(minDewPointData && maxDewPointData) {
                setMinDewPoint(minDewPointData.dewPoint);
                setMinDewPointTime(minDewPointData.time);
                setMaxDewPoint(maxDewPointData.dewPoint);
                setMaxDewPointTime(maxDewPointData.time);
            }
        }
    }, [all]);
    const getMinDewPoint = () => {
        return all.filter(item => dayjs(item.SEND_TIME).isAfter(dayjs().startOf('day'))).map(item => ({
            time: dayjs(item.SEND_TIME).format('HH:mm'),
            dewPoint: parseFloat(item.D_POINT).toFixed(2)
        }))?.sort((a, b) => a.dewPoint - b.dewPoint)[0]
    };
    const getMaxDewPoint = () => {
        return all.filter(item => dayjs(item.SEND_TIME).isAfter(dayjs().startOf('day'))).map(item => ({
            time: dayjs(item.SEND_TIME).format('HH:mm'),
            dewPoint: parseFloat(item.D_POINT).toFixed(2)
        }))?.sort((a, b) => b.dewPoint - a.dewPoint)[0]
    };

    return (
        <DataBox title={t('Tačka rosišta')} unit="°C"
                 value={now?.dewp || 0}
                 subValue={`${t('Trend (1h)')}: ${parseFloat(trend?.diff_D_POINT_hour || 0).toFixed(2)} °C`}
                 sizes="w-1/2 sm:w-1/4"
                 uppercase
                 bottomLeft={
                     <div className="flex flex-col">
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Min')} {minDewPoint}°C</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{minDewPointTime}</span>
                     </div>
                 }
                 bottomRight={
                     <div className="flex flex-col">
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{t('Max')} {maxDewPoint}°C</span>
                         <span
                             className="text-zinc-700 dark:text-slate-400 text-xs font-bold text-center">{maxDewPointTime}</span>
                     </div>
                 }
        />
    )
}

export default DewPointBox;
