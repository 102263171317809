import dayjs from "dayjs";

const PROXY_URL = 'https://vss-proxy-server.vercel.app/api';
export const getStationData = async (station_id) => {
    return await fetch(PROXY_URL,{
        headers: {
            'api-url': `http://flumen.club/wp/data/rest.php/stations?filter=ST_ID,eq,${station_id}&t=${Date.now()}`,
        }
    })
        .then(response => response.json())
        .then(data => {
            data = data?.stations;
            const formatted = {};
            data?.columns?.forEach((item, index) => {
                formatted[item] = data.records[0][index];
            })
            formatted.last_update = dayjs().format("DD.MM.YYYY HH:mm");
            return formatted;
        })
}

export const getNow = async (station_id) => {
    return await fetch(PROXY_URL,{
        headers: {
            'api-url': `http://flumen.club/wp/data/rest.php/Now?filter=Station,eq,${station_id}&t=${Date.now()}`,
        }
    })
        .then(response => response.json())
        .then(data => {
            data = data?.Now;
            const formatted = {};
            if (data?.records?.length > 0) {
                data?.columns?.forEach((item, index) => {
                    formatted[item] = data.records[0][index];
                })
            }
            formatted.last_update = dayjs().format("DD.MM.YYYY HH:mm");
            return formatted;
        })
}

export const getAllData = async (station_id) => {
    return await fetch(PROXY_URL,{
        headers: {
            'api-url': `http://flumen.club/wp/data/rest.php/view?columns=SEND_TIME,WIND_SP,WIND_GUST,WIND_MAX,WIND_ANG,WIND_DIR,ST_ID,TEMP,ST_ID,CL_BASE,D_POINT,PRESSURE,MOIST&filter=ST_ID,EQ,${station_id}&order=TS,desc&t=${Date.now()}`,
        }
    })
        .then(response => response.json())
        .then(data => {
            data = data.view;

            const formatted = [];
            if (data?.records?.length > 0) {
                data?.records?.forEach((item) => {
                    let formattedData = {};
                    item.forEach((v, i) => {
                        formattedData[data?.columns[i]] = v
                        return formattedData;
                    })
                    formatted.push(formattedData);
                })
            }
            return formatted;
        })
}

export const getAvgWindDirectionData = async (station_id, interval = 15) => {
    return await fetch(PROXY_URL,{
        headers: {
            'api-url': `http://flumen.club/wp/averageDirection.php?station=${station_id}&interval=${interval}&t=${Date.now()}`,
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getInterval = async (station_id, interval = 15) => {
    return await fetch(PROXY_URL,{
        headers: {
            'Content-Type': 'application/json',
            'api-url': `http://flumen.club/wp/data/rest.php/Interval${interval}?filter=Station,eq,${station_id}&t=${Date.now()}`,
        }
    })
        .then(response => response.json())
        .then(data => {
            data = data['Interval' + interval];
            const formatted = {};
            if (data?.records?.length > 0) {
                data?.columns?.forEach((item, index) => {
                    formatted[item] = data.records[0][index];
                })
            }
            return formatted;
        })
}

export const getTrend = async (station_id) => {
    return await fetch(PROXY_URL,{
        headers: {
            'api-url': `http://flumen.club/wp/zbelacRain.php?station=${station_id}&t=${Date.now()}`,
        }
    })
        .then(response => response.json())
        .then(data => {
            return data[0] || {};
        })
}

export const getSkewT = async (lat,lng, date, time, model) => {
    return await fetch(PROXY_URL, {
        method: 'POST',
        headers: {
            'origin': 'https://skysight.io',
            'referer': 'https://skysight.io',
            'api-url': `https://apig2.skysight.io/model`
        },
        body: JSON.stringify({
            "req": "skewt",
            "lat": parseFloat(lat),
            "lon": parseFloat(lng),
            "date": dayjs(date).format('YYYYMMDD'),
            "hour": dayjs.utc(time).format('HHmm'),
            "region": model,
        })
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}


export const getPointForecast = async (lat,lng, times, model) => {
    return await fetch(PROXY_URL, {
        method: 'POST',
        headers: {
            'origin': 'https://skysight.io',
            'referer': 'https://skysight.io',
            'api-url': `https://apig2.skysight.io/model`
        },
        body: JSON.stringify({
            "req": "table",
            "lat": parseFloat(lat),
            "lon": parseFloat(lng),
            "times": times,
            "region": model,
        })
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export default { getStationData, getNow, getAllData, getAvgWindDirectionData, getInterval, getTrend, getSkewT, getPointForecast}
