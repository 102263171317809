import DataBox from "../DataBox";
import {useTranslation} from "react-i18next";

const SolarBox = ({now, trend}) => {
    const {t} = useTranslation();

    return (
        <DataBox title={t('Osvetljenje')} unit="W/m2"
                 value={now?.SUN || 0}
                 subValue={`Trend (1h): ${trend?.diff_HEAT_INDEX_hour || 0} W/m2`}
                 sizes="w-1/2 sm:w-1/4"
                 uppercase
        />
    )
}

export default SolarBox;
