import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {useContext, useEffect, useState} from "react";
import {WeatherDataContext} from "../../contexts/WeatherDataContextProvider";
import {isMobile} from "react-device-detect";
import {getWindDirection} from "../../utils";
import {useTranslation} from "react-i18next";

const directions = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
const speeds = [
    {
        title: `< 0.5 m/s`,
        condition: (speed) => speed < 0.5,
    },
    {
        title: `0.5 - 2 m/s`,
        condition: (speed) => speed >= 0.5 && speed < 2,
    },
    {
        title: `2 - 4 m/s`,
        condition: (speed) => speed >= 2 && speed < 4,
    },
    {
        title: `4 - 6 m/s`,
        condition: (speed) => speed >= 4 && speed < 6,
    },
    {
        title: `6 - 8 m/s`,
        condition: (speed) => speed >= 6 && speed < 8
    },
    {
        title: `8 - 10 m/s`,
        condition: (speed) => speed >= 8 && speed < 10,
    },
    {
        title: `> 10 m/s`,
        condition: (speed) => speed >= 10,
    }
]

export const WindRoseChart = () => {
    const {allData, getAllDataInterval} = useContext(WeatherDataContext);
    const [series, setSeries] = useState([]);
    const {t} = useTranslation();


    useEffect(() => {
        if (allData.length > 0) {
            getAllDataInterval(30).then(data => {
                setSeries(formatSeries(data));
            });
        }
    }, [allData]);


    const formatSeries = (data) => {
        const seriesData = {};
        speeds.forEach(speed => {
            directions.forEach(direction => {
                seriesData[speed.title] = [...seriesData[speed.title] || [], [direction, data.filter(({WIND_SP}) => speed.condition(WIND_SP))
                    .filter(({WIND_ANG}) => getWindDirection(WIND_ANG) === direction)
                    .length / data.length * 100]]
            });
        });

        return Object.keys(seriesData).map((key, index) => ({
            name: t(key),
            data: seriesData[key],
            _colorIndex: index
        }));
    }


    const options = {
        series,
        chart: {
            polar: true,
            type: 'column',
            styledMode: false,
        },

        title: {
            text: '',
        },

        pane: {
            size: '85%'
        },

        legend: {
            align: isMobile ? 'center' : 'right',
            verticalAlign: isMobile ? 'bottom' : 'top',
            y: 0,
            layout: isMobile ? 'horizontal' : 'vertical',
        },

        xAxis: {
            tickmarkPlacement: 'on',
            type: 'category'
        },

        yAxis: {
            min: 0,
            endOnTick: false,
            showLastLabel: true,
            title: {
                text: `${t('Procenat')} (%)`
            },
            labels: {
                formatter: function () {
                    return this.value + '%';
                }
            },
            reversedStacks: false
        },

        tooltip: {
            valueSuffix: '%',
            valueDecimals: 2
        },

        plotOptions: {
            series: {
                stacking: 'normal',
                shadow: false,
                groupPadding: 0,
                pointPlacement: 'on'
            }
        }
    };

    return <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />
}
