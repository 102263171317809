import {forwardRef, useEffect, useMemo, useRef, useState} from "react";
import SkewT from "../../skewt/bundle";
import {getSkewT} from "../../services/api";
import "../../skewt/skewt.css"
import {useForecastContext} from "../../contexts/ForecastContextProvider";
import {isDesktop} from "react-device-detect";


const SkewTDiagram = forwardRef(({}, ref) => {
    const skewTDivRef = useRef(null);
    const skewT = useRef(null);

    const {position, date, time, model} = useForecastContext();
    const [skewTData, setSkewTData] = useState(null)
    const [skewTRendered, setSkewTRendered] = useState(false)


    const parsedTime = useMemo(() => {
        if (time) {
            const [hour, minute] = time.value.split(":");
            return new Date().setHours(hour, minute);
        }
    }, [time])

    useEffect(() => {
        if (position && date && parsedTime && model) {
            (async () => {
                const skewTData = await getSkewT(position.lat, position.lng, date, parsedTime, model);
                setSkewTData(skewTData);
            })()
        }
    }, [position, date, parsedTime, model]);

    useEffect(() => {
        if (skewTData && !skewTRendered) {
            skewT.current = new SkewT(skewTDivRef.current);
            setSkewTRendered(true)
        }

        if (skewTData && skewT.current) {
            skewT.current.plot(skewTData);
            const landscape = window.innerWidth > window.innerHeight;
            const width = landscape ? window.innerHeight : window.innerWidth;
            skewT.current.setParams({
                basep: skewTData[0].press,
                topp: skewTData[skewTData.length - 1].press,
                height: !isDesktop ? landscape ? width * 0.82 : width + 100 : window.innerHeight - 165 <= 1100 ? window.innerHeight - 165 : 1100
            });
        }
    }, [skewTData, skewTRendered, skewT])

    useEffect(() => {
        const updateWindowDimensions = () => {
            const landscape = document.documentElement.clientWidth > document.documentElement.clientHeight;
            const newHeight = landscape ? document.documentElement.clientHeight : document.documentElement.clientWidth;
            if (skewT.current) {
                skewT.current.setParams({
                    height: !isDesktop ? landscape ? newHeight * 0.82 : newHeight + 100 : newHeight - 165 <= 1100 ? newHeight - 165 : 1100
                });
            }
        }

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, [])

    return (
        <div className="w-full max-h-screen" ref={ref}>
            <div className="skew-t" ref={skewTDivRef}/>
        </div>
    )
})

export default SkewTDiagram;
