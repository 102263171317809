import {useForecastContext} from "../../contexts/ForecastContextProvider";

const ModelPicker = () => {
    const {model, setModel} = useForecastContext()
    return (
        <div className={`flex`}>
            <div>
                <span
                    className={`text-base sm:text-sm dark:text-zinc-200 px-3 py-1 rounded-3xl cursor-pointer ${model === 'EUROPE' ? 'text-white font-bold bg-blue-500/80 dark:bg-blue-500/40' : 'text-slate-800'}`}
                    onClick={() => {
                        setModel('EUROPE')

                    }}>EU</span>
                <span
                    className={`text-base sm:text-sm  dark:text-zinc-200 px-3 py-1 rounded-3xl cursor-pointer ${model=== 'ICON' ? 'text-white font-bold bg-blue-500/80 dark:bg-blue-500/40' : 'text-slate-800'}`}
                    onClick={() => {
                        setModel('ICON')

                    }}>ICON</span>
            </div>
        </div>
    )
}

export default ModelPicker;
